const setClockHands = () => {
  const date = new Date();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();
  const milliseconds = date.getMilliseconds();

  const hoursAngle = hours * 30 + minutes / 2;
  const minutesAngle = minutes * 6;
  const secondsAngle = (seconds + milliseconds / 1000) * 6;

  document.getElementById('hours').style.transform =
    `rotateZ(${hoursAngle}deg)`;
  document.getElementById('minutes').style.transform =
    `rotateZ(${minutesAngle}deg)`;
  document.getElementById('seconds').style.transform =
    `rotateZ(${secondsAngle}deg)`;

  requestAnimationFrame(setClockHands);
};

requestAnimationFrame(setClockHands);
